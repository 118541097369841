<template>
	<div>
		<div class="container-fluid">
			<div class="container">
				<div class="row">
					<div class="col text-left title">
						<h4>{{ title }}</h4>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-white hh">
			<div class="container">
				<div class="row py-4">
					<div class="col-6" v-if="response && success">
						<p>U ontvangt zo spoedig mogelijk bericht van ons.</p>
						<br />
						<p class="font-weight-bold">Hartelijke groet, Simone Busscher</p>
					</div>
					<div class="col-6" v-if="response && !success">
						<p>
							Blijkbaar is er iets misgegaan tijdens het bestellen. Wilt u alsnog bestellen, vul dan het
							<a href="/tarieven">bestelformulier</a> opnieuw in en rond de bestelling af. Lukt dit niet,
							stuur dan een mail naar <a href="info:info@simonebusscher.nl">info@simonebusscher.nl</a>
						</p>
						<br />
						<p>Hartelijke groet, Simone Busscher</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Bestellen',
	data: () => ({
		URL: '/php/mollie-api-php/examples/',
		title: '',
		titleSuccess: 'Bedankt voor uw bestelling!',
		titleError: 'Er is iets misgegaan...',
        response: false,
        success: false,
	}),

	components: {},

	created() {
		// check status of last order
		this.checkLastOrderStatus();
	},

	methods: {
		checkLastOrderStatus: async function () {
			// read from local storage
			let orderId;
			if (sessionStorage.getItem('orderId')) {
				orderId = JSON.parse(sessionStorage.getItem('orderId'));
			}

			// get payments and check status of last order
			await axios
				.get(this.URL + 'payments.php')
				.then((result) => {
                    this.response = true;
					let orders = result.data;
					// console.log('length', Object.keys(orders).length);
					// console.log(orders[0].id);
					if (orders[0].id == orderId) {
						this.success = orders[0].status == 'paid';
						this.title = this.success ? this.titleSuccess : this.titleError;
					}
				})
				.catch((error) => {
					console.log(error);
				});

			// if payment succeeded
			if (this.success) {
				// get order info
				let orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));
				let cart = JSON.parse(sessionStorage.getItem('cart'));
				let order = {};
				for (let i in orderInfo) order[i] = orderInfo[i];
				cart.forEach((element) => {
					for (let i in element) order[i] = element[i];
				});

				// send confirmation mail
				console.log('send confirmation email');
				await axios
					.post('/php/sendordermail.php', order)
					.then((result) => {
						console.log('confirmation mail sent');
					})
					.catch((error) => {
						console.log(error);
					});

				// store in kvstore database
				console.log('store in database');
				await axios
					.post('/php/db-sqlite.php/kvstore', {
						key: orderId,
						value: JSON.stringify({ orderInfo: orderInfo, cart: cart }),
					})
					.then((result) => {
						console.log('kvstore', result);
					})
					.catch((error) => {
						console.log(error);
					});

				// clear local storage
				sessionStorage.removeItem('orderId', '');
				sessionStorage.removeItem('orderInfo', '');
				sessionStorage.removeItem('cart');
			}
		},
	},
};
</script>

<style scoped>
.hh {
	max-height: 500px;
}
</style>
